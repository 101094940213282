*{ 
  outline: none;
  border: 0px;
}

body {
  background-color: #1a1c1f;
}

.App {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
}

 .App header {
  background-color: #3c3c3c;
  color: white;
  position: fixed;
  width: 100%;
  height: 10vh;
  min-height: 50px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}
.title {
  display: flex;
  justify-content: left;
  color: #4e91f0;
}


.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(29, 29, 29);
}



main {
  padding: 10px;
  height: 70vh;
  margin: 10vh 0 10vh;
  overflow-y:auto;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  scrollbar-color: #199df0 #1e1e24;
  scrollbar-width: thin;
}

main::-webkit-scrollbar {
  width: 10px;
}

main::-webkit-scrollbar-track {
  background: #4d4d55;
  border-radius: 10px;
}

main::-webkit-scrollbar-thumb {
  background: #009dff92;
  border-radius: 10px;
}


.form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: #181717;
  width: 100%;
  max-width: 100%;
  display: flex;
  font-size: 1.5rem;
}

.btn {
  width: 10%;
  color: #7c7c7c;
  background-color: #3a3a3a;
  font-size: 30px;
  transition: 400ms;
}

.btn:enabled {
  color: #0b93f6;
}


.input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  line-height: 1.5;
  width: 100%;
  word-wrap: break-word;
  font-size: 35px;
  resize:none;
  background: #3a3a3a;
  color: white;
  outline: none;
  border: none;
  padding: 10px;
  padding-left: 30px;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px ;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

.btn:disabled {
  cursor: not-allowed;
}

@keyframes fade {
  0%, 50% {
  box-shadow: 0 0 25px transparent;
  }
  100% {
  box-shadow: 0 0 25px #0b93f6;
  }
}

.sign-in {
  position: relative;
  color: #2c3342;
  background: #fff;
  width: 400px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 20px;
  font-weight: bold;
  border-radius: 15px;
  /* border: 1.5px solid white; */
  animation: click 1s alternate infinite;
}

ul, li {
  text-align: left;
  list-style: none;
}

p {
  display: inline-block;
  max-width: 500px;
  /* white-space: pre-wrap; */
  word-wrap: break-word;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: 600;
  position: relative;
  color: white;
  text-align: center;
}

.uname {
  font-size: 15px;
}

.message {
  display: flex;
  align-items: center;
}


.sent {
  flex-direction: row-reverse;
}

.sent .p {
  color: white;
  background: #6f6f6f;
  align-self: flex-end;
  text-align: right;
}
.received .p {
  background: #3c3c3c;
  color: #fff;
  text-align: left;
}

.staff .img {
  border: 3px solid #00d0ff62;
}

.staff .p {
  border: 3px solid #00d0ff62;
}

.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.sent .img {
  margin-left: 5px;
}
.received .img {
  margin-right: 5px;
}

.text {
  position: relative;
  font-size: 30px;
  font-weight: 600;
  line-height: 50px;
}

.inp {
  line-height: 1.5;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
}

.settingsmenu {
  position: fixed;
  background-color: #282c34;
  color: white;
  border: none;
  width: 400px;
  border-radius: 10px;
  height: 400px;
}

.name {
  word-wrap: break-word;
  position: relative;
  top: 100px;
  font-size: 30px;
  user-select: none;
}

.userimg { 
  position: relative;
  top: 90px;
  width: 130px;
  height: 130px;
  user-select: none;
}

.sign-out {
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 5px;
  color: white;
  border-radius: 5px;
  transition: 400ms;
  font-size: 15px;
  border: #ef6868 1.5px solid;
  user-select: none;
}

.sign-out:hover {
  padding: 5px;
  user-select: none;
  border-radius: 5px;
  background-color: #f64a4a;
  color: white;
}

dialog {
  transition: 400ms;
}

dialog[open] {
  animation: open 0.7s alternate;
}

dialog::backdrop {
  animation: shadow 400ms alternate;
  background-color: black;
  opacity: .5;
}
@keyframes shadow {
  0% {
    background-color: black;
    opacity: 0;
  }
  100% {
    background-color: black;
    opacity: .5;
  }
}

@keyframes open {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.closeb {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 25px;
  cursor: pointer;
  color: #656d7d;
  user-select: none;
  transition: 400ms;
}

.closeb:hover {
  color: #868fa2;
}

.i {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 15px;
    font-weight: 700;
    color: #656d7d;
    user-select: none;
}

.tech {
  color: #fff;
}

.settings {
  transition: 400ms;
  filter: grayscale(30%);
}

.settings:hover {
  cursor: pointer;
  filter: grayscale(0%);
}

.esc {
  font-size: 17px;
  font-weight: bold;
}

.crown {
  font-size: 16px;
  color: #00d0ff;
}

.google {
  position: absolute;
  left: -12px;
  width: 70px;
  height: 70px;
  color: #282c34;
  background-color: white;
  font-size: 30px;
  padding: 5px;
  -webkit-user-drag: none;
  user-select: none;
  top: -13px;
}

@media (max-width: 400px) {
  p {
    display: inline-block;
    max-width: 120px;
    /* white-space: pre-wrap; */
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    font-size: 15px;
    text-align: center;
  }
  .title {
    display: none;
  }
  .input {
    font-size: 20px;
    width: 240px;
  }

  .btn {
    width: 50%;
  }

  .text {
    font-size: 15px;
    /* line-height: 32px; */
  }
}

@media (min-width: 401px) and (max-width: 610px) {
  p {
    display: inline-block;
    max-width: 300px;
    word-wrap: break-word;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
  }
  .text {
    line-height: 44px;

  }
}


